/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import {
  Button as ShadcnButton,
  type ButtonProps,
} from '@/components/ui/button';

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-2 font-medium transition-colors rounded-sm ring-offset-[#0A343C] focus:ring focus:ring-offset-2 focus:ring-[#40565A] disabled:cursor-not-allowed',
  {
    variants: {
      customVariant: {
        primary:
          'bg-[#34BCAF]/80 hover:bg-opacity-10 active:bg-[#34BCAF]/[0.48] text-white disabled:bg-[#40565A]',
        secondary:
          'bg-transparent border border-white hover:bg-[#EBEFF1] hover:text-[#40565A] active:bg-[#EBEFF1]/[64%] active:text-[#08242F] text-white disabled:border-form-input-disabled disabled:text-form-input-disabled',
      },
      customSize: {
        small: 'h-5 px-2.5 text-[10px]',
        regular: 'h-7 px-4 text-[13px]',
        large: 'h-[42px] px-4 text-[13px]',
      },
    },
    defaultVariants: {
      customVariant: 'primary',
      customSize: 'regular',
    },
  }
);

type IconPosition = 'left' | 'right';

interface CustomButtonProps
  extends ButtonProps,
    VariantProps<typeof buttonVariants> {
  loader?: React.ReactNode;
  icon?: React.ReactNode;
  iconPosition?: IconPosition;
  isLoading?: boolean;
}

export const CustomButton = React.forwardRef<
  HTMLButtonElement,
  CustomButtonProps
>(
  (
    {
      className,
      variant, // Original shadcn variant
      size, // Original shadcn size
      customVariant,
      customSize,
      isLoading,
      disabled,
      loader,
      icon,
      iconPosition = 'left',
      children,
      ...props
    },
    ref
  ) => {
    const content = React.useMemo(() => {
      if (isLoading) {
        return (
          <span className="flex items-center gap-1">
            {loader}
            {children}
          </span>
        );
      }

      if (icon) {
        return (
          <>
            {iconPosition === 'left' && icon}
            {children}
            {iconPosition === 'right' && icon}
          </>
        );
      }

      return children;
    }, [children, icon, iconPosition, isLoading, loader]);

    // Only apply custom variants if no shadcn variant is provided
    const buttonClassName =
      variant || size
        ? className // If shadcn variant is provided, only use the passed className
        : cn(buttonVariants({ customVariant, customSize }), className); // Otherwise, use custom variants

    return (
      <ShadcnButton
        ref={ref}
        disabled={disabled || isLoading}
        variant={variant}
        size={size}
        className={buttonClassName}
        {...props}
      >
        {content}
      </ShadcnButton>
    );
  }
);

CustomButton.displayName = 'CustomButton';
