const BASE_URL_DATA = `${process.env.NEXT_PUBLIC_OKAHU_API_URL}/${process.env.NEXT_PUBLIC_OKAHU_API_VERSION}`;
const MOCK_BASE_URL_DATA = `${process.env.NEXT_PUBLIC_MOCK_API_URL}/${process.env.NEXT_PUBLIC_OKAHU_API_VERSION}`;
const BASE_URL_MGMT = `${process.env.NEXT_PUBLIC_OKAHU_MGMT_API_URL}${process.env.NEXT_PUBLIC_OKAHU_MGMT_API_VERSION}`;
const MAX_LOG_IN_TIME = process.env.NEXT_PUBLIC_OKAHU_MAX_LOG_IN_TIME ?? '24';
let url = BASE_URL_DATA;

interface ReqParams {
  endpoint: string;
  queryParams?: { [key: string]: string };
}

export const LOGGEDIN_OKAHU_USER = 'USER_SESSION_START';

let refreshTokenPromise: Promise<any> | null = null;

const genUrl = (
  endpoint: string,
  queryParams?: { [key: string]: string },
  mgmt?: boolean
): string => {
  // Construct query string from queryParams object
  const queryString = queryParams
    ? '?' + new URLSearchParams(queryParams).toString()
    : '';

  // return the full url
  return `${!mgmt ? url : BASE_URL_MGMT}/${endpoint}` + queryString;
};

const getAccessToken = async (grant = false) => {
  try {
    const res = await fetch(`/api/access-token?grant=${grant}`);
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const token = await res.json();
    return token;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const ensureAccessToken = async (session: any, grant?: boolean) => {
  const timeNow = Date.now();
  const sessionExpiry = session?.accessTokenExpiresAt;
  const loginTime = window.localStorage.getItem(`${LOGGEDIN_OKAHU_USER}`);

  const loginTimeElapsedInSec = (timeNow - parseInt(loginTime!)) / 1000;
  // timeToLogout = hh*mm*ss
  const timeToLogout = parseInt(MAX_LOG_IN_TIME) * 60 * 60;

  // this if block checks if the login time has exceeded the max time for user to stay logged in
  if (
    Number.isNaN(loginTimeElapsedInSec) ||
    loginTimeElapsedInSec < timeToLogout
  ) {
    if (timeNow > sessionExpiry * 1000) {
      if (!refreshTokenPromise) {
        refreshTokenPromise = getAccessToken(grant);
      }

      // Wait for the existing refreshTokenPromise to resolve
      const newToken = await refreshTokenPromise;
      refreshTokenPromise = null;
      return newToken;
    }
  } else {
    console.log(`User logout: ${timeToLogout / 3600} hours has elapsed`);
    window.location.href = '/api/auth/logout';
    window.localStorage.removeItem(`${LOGGEDIN_OKAHU_USER}`);
    window.localStorage.removeItem(`accessToken`);
  }

  return null; // no new token required
};

const getSession = async () => {
  try {
    const res = await fetch('/api/session');
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const session = await res.json();
    return session;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updateOptions = async (
  options: RequestInit,
  newToken: any,
  session: any,
  body?: any
) => {
  const token = newToken?.accessToken || session.accessToken;
  const headers: Record<string, string> = {
    Authorization: `Bearer ${token}`,
  };

  if (['POST', 'PUT', 'PATCH'].includes(options.method!)) {
    headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(body);
  }

  return { ...options, headers };
};

const genOptions = async (
  options: RequestInit,
  accessToken: any,
  session: any,
  body?: any
) => {
  switch (options.method) {
    case 'GET':
    case 'DELETE':
      return await updateOptions(options, accessToken, session);
    case 'POST':
    case 'PUT':
    case 'PATCH':
      return await updateOptions(options, accessToken, session, body);
    default:
      throw new Error(
        'Method not available. Available methods are GET, POST, PUT, PATCH and DELETE'
      );
  }
};

const makeRequest = async (url: string, options: RequestInit) => {
  try {
    const res = await fetch(url, options);
    const contentType = res.headers.get('content-type');
    let data;

    if (contentType?.includes('application/json')) {
      try {
        data = await res.json();
      } catch (jsonError) {
        throw new Error('Failed to parse JSON response.');
      }
    } else {
      data = await res.text(); // Fallback for non-JSON responses
    }

    if (!res.ok) {
      throw {
        status: res.status,
        statusText: res.statusText,
        data, // Now includes the parsed data (either JSON or text)
      };
    }

    return data;
  } catch (error) {
    console.error(`Error fetching from ${url}`, error);
    throw error;
  }
};

// this function checks whether the incoming request is of ReqParams type or
// ReqParams[] and accordingly processes the request
const processReq = async (
  req: ReqParams | ReqParams[],
  options: RequestInit,
  body?: any,
  mgmt?: boolean,
  grant?: boolean
) => {
  const session = await getSession();
  const accessToken = await ensureAccessToken(session, grant);

  const reqArr = Array.isArray(req) ? req : [req];

  // map over the endpoints array and make an array of requests
  const requests = reqArr.map(async (each: any) => {
    const { endpoint, queryParams } = each;

    const url = mgmt
      ? genUrl(endpoint, queryParams, mgmt)
      : genUrl(endpoint, queryParams);
    const updatedOptions: RequestInit = await genOptions(
      options,
      accessToken,
      session,
      body
    );

    // wait for all the requests to complete and return the promises array
    return makeRequest(url, updatedOptions!);
  });

  // wait for all the requests to complete and return the promises array
  return Promise.all(requests);
};

export const request = {
  get: async (req: ReqParams | ReqParams[], grant?: boolean) => {
    const options: RequestInit = {
      method: 'GET',
    };

    return processReq(req, options, grant);
  },

  put: async (req: ReqParams | ReqParams[], body?: any) => {
    const options: RequestInit = {
      method: 'PUT',
    };

    return processReq(req, options, body);
  },

  post: async (req: ReqParams | ReqParams[], body?: any, mgmt?: boolean) => {
    const options: RequestInit = {
      method: 'POST',
    };

    return processReq(req, options, body, mgmt);
  },

  patch: async (req: ReqParams | ReqParams[], body?: any) => {
    const options: RequestInit = {
      method: 'PATCH',
    };

    return processReq(req, options, body);
  },

  delete: async (req: ReqParams | ReqParams[]) => {
    const options = {
      method: 'DELETE',
    };

    return processReq(req, options);
  },
  setupMock: () => {
    url = MOCK_BASE_URL_DATA;
  },
  setupMockTearDown: () => {
    url = BASE_URL_DATA;
  },
};
