/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

import { Badge } from '../ui/badge';

const customBadgeVariants = cva('justify-center py-0 px-0', {
  variants: {
    customVariant: {
      default: 'bg-body-secondary text-canvas-background-primary border-0',
      defaultInverse: 'bg-card-02 text-body-subtle border-0',
      primary: 'bg-ocean text-body-primary border-0',
      primaryInverse: 'bg-[#07181E] text-jade border-0',
      outline: 'border border-line-secondary bg-card-02 text-body-subtle',
    },
    customSize: {
      sm: 'size-3 text-[9px] leading-none font-medium',
      regular: 'size-[19px] text-[11px] font-normal leading-none',
      lg: 'size-[23px] text-sm font-normal leading-none',
    },
  },
  defaultVariants: {
    customVariant: 'default',
    customSize: 'regular',
  },
});

export interface customBadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof customBadgeVariants> {
  variant?: 'default' | 'secondary' | 'destructive' | 'outline';
}

export function CustomBadge({
  className,
  customVariant,
  customSize,
  variant = 'outline',
  ...props
}: customBadgeProps) {
  return (
    <Badge
      variant={variant}
      className={cn(
        customBadgeVariants({ customVariant, customSize }),
        className
      )}
      {...props}
    />
  );
}
