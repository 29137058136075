/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { toast } from 'sonner';

export const ErrorToast = (toastMsg: string) =>
  toast.error(toastMsg, {
    duration: 5000,
    // cancel: (
    //   <div className="cursor-pointer" onClick={() => toast.dismiss()}>
    //     {Icons.Close('size-4')}
    //   </div>
    // ),
  });
